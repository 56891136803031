import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube, FaLinkedinIn } from 'react-icons/fa';

const Footer = () => {
    return (
      <footer>
      <div class="content">
        <div class="left box">
          <div class="upper">
            <div class="topic">PRINCESS BUTIK</div>
            <p>Онлайн магазин Princess е специализиран в предлагането на колекции от официални, ежедневни облекла и аксесоари, които със своята изящност и стил, дават допълнително самочувствие и удовлетвореност на клиента, избрал да се довери на нашият бранд.</p>
          </div>
          <div class="lower">
            <div class="topic">Връзка с нас</div>
            <div class="phone">
              <a href="#"><i class="fas fa-phone-volume"></i> +359 898 764 573</a>
            </div>
            <div class="email">
              <a href="#"><i class="fas fa-envelope"></i> princessbutik@abv.bg</a>
            </div>
          </div>
        </div>
        <div class="middle box">
          <div class="topic">За клиенти</div>
          <div><Link to="/files/Доставка и плащане.rtf" target="_blank" download>Доставка и плащане</Link></div>
          <div><Link to="/files/Връщане и замяна.rtf" target="_blank" download>Връщане и замяна</Link></div>
          <div><Link to="/files/ОБЩИ УСЛОВИЯ ЗА ПОЛЗВАНЕ НА .docx" target="_blank" download>Общи условия</Link></div>
          <div><Link to="/files/ПРАВО НА ОТКАЗ ПРИ ЗАКУПЕНА СТОКА.docx" target="_blank" download>Отказ при закупена стока</Link></div>
          <div>
          <p style={{color: 'red'}}>Доставяме вашите поръчки с куриерска фирма Еконт до техен офис или ваш адрес</p>
          </div>
        </div>
        <div class="right box">
          <div class="topic">Абонирайте се за нас</div>
          <form action="#">
            {/* <input type="text" placeholder="Въведете вашия email address" />
            <input type="submit" name="" value="Абонирайте се" /> */}
            <div class="media-icons">
              <a target="_blank" href="https://www.facebook.com/Princess-BUTIK-111707133943126/"><FaFacebookF /></a>
              <a target="_blank" href="https://www.instagram.com/princessbutik.bg/"><FaInstagram /></a>
              <a href="#"><FaTwitter /></a>
              <a href="#"><FaYoutube /></a>
              <a href="#"><FaLinkedinIn /></a>
            </div>
          </form>
        </div>
      </div>
      <div class="bottom">
        <p>Copyright &#169; 2025 <a href="#">Princess butik</a> Всички права запазени</p>
      </div>
    </footer>
    )
}

export default Footer;
