import React, { useContext } from 'react';
import { GlobalState } from '../../../../GlobalState';
import { Link } from 'react-router-dom';
import styled from "styled-components";
import { mobile } from "../responsive";

const Container = styled(Link)`
  flex: 1;
  margin: 3px;
  height: 60vh;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;
  background: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  &:hover {
    transform: scale(1.05);
  }

  ${mobile({ 
    height: "50vh", 
    width: "100%" 
  })}
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 10px;

  ${mobile({ height: "50vh", width: "100%" })}
`;

const Info = styled.div`
width: 50%;
  position: absolute;
  bottom: 20px;
  left: 0;
  //background: rgba(255, 255, 255, 0.9);
  background-color: white;
  padding: 12px 20px;
  /* border-radius: 5px; */
  font-weight: bold;
  font-size: 16px;
  color: black;

  ${mobile({ 
    padding: "8px 15px", 
    fontSize: "14px" 
  })}
`;

const CategoryItem = ({ item }) => {
  const state = useContext(GlobalState);
  const [category, setCategory] = state.productsAPI.category;
  const words = item.img.split("id=");

  return (
    <Container to="/products" onClick={() => setCategory("category=" + item._id)}>
      <Image src={`https://lh3.googleusercontent.com/d/${words[1]}=s500?authuser=1`} referrerPolicy="no-referrer" />
      <Info>{item.name}</Info>
    </Container>
  );
};

export default CategoryItem;
