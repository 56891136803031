import React from 'react';
import './featureSection.css';
import { FaShippingFast, FaLock, FaUndo, FaHeadset } from 'react-icons/fa';

const FeatureSection = () => {
  return (
    <div className="features-container">
      <div className="feature-item">
        <FaShippingFast className="feature-icon" />
        <h3>Безплатна доставка</h3>
        <p>За поръчки над 100 лв</p>
      </div>
      <div className="feature-item">
        <FaLock className="feature-icon" />
        <h3>Сигурно плащане</h3>
        <p>С много възможности</p>
      </div>
      <div className="feature-item">
        <FaUndo className="feature-icon" />
        <h3>Връщане до 14 дни</h3>
        <p>Пазарувай уверено</p>
      </div>
      <div className="feature-item">
        <FaHeadset className="feature-icon" />
        <h3>24/7 Обслужване</h3>
        <p>Доставка на място или до офис на Еконт</p>
      </div>
    </div>
  );
};

export default FeatureSection;
