import React, { useContext } from 'react';
import { GlobalState } from '../../../../GlobalState';
import { Link } from 'react-router-dom';
import {
  FavoriteBorderOutlined,
  SearchOutlined,
  ShoppingCartOutlined,
} from "@material-ui/icons";
import './products.css';

const Product = ({ item }) => {
  const state = useContext(GlobalState);
  const addCart = state.userAPI.addCart;

  const words = item.images.url.split("id=");

  return (
    <div className="product-container">
      <img className="product-image" src={`https://lh3.googleusercontent.com/d/${words[1]}=s500?authuser=1`} alt="product" />
      <div className="product-overlay">
        <button className="product-button" onClick={() => addCart(item)}>
          <ShoppingCartOutlined />
        </button>
        <Link to={`/detail/${item._id}`} className="product-button">
          <SearchOutlined />
        </Link>
      </div>
      <div className="product-label">New</div>
      <h3 className="product-title">{item.title}</h3>
      <p className="product-price">{item.price} лв.</p>
    </div>
  );
};

export default Product;
