import React, { useEffect, useContext } from 'react';
import Slider from "react-slick";
import Product from './Product';
import { GlobalState } from '../../../../GlobalState';
import axios from 'axios';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './products.css';

const Products = () => {
  const state = useContext(GlobalState);
  let [products, setProducts] = state.productsAPI.products;

  useEffect(() => {
    const getProducts = async () => {
      const res = await axios.get('/api/products');
      setProducts(res.data.products);
    };
    getProducts();
  }, [setProducts]);

  // Настройки за слайдера
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    centerMode: false,
    centerPadding: "0px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          centerPadding: "20px",
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          centerPadding: "15px",
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1.4,
          centerPadding: "10px",
        }
      }
    ]
  };

  return (
    <>
      <div className="div_new_offer">
        <h1>НАЙ - НОВИ ПРЕДЛОЖЕНИЯ</h1>
        <div className="view-all-button">
          <a href="/products">
            Виж всички 
          </a>
        </div>
      </div>
      <Slider {...settings} className="product-slider">
        {products.map((item) => (
          <Product item={item} key={item._id} />
        ))}
      </Slider>
    </>
  );
};

export default Products;