import React, { useContext, useState, useEffect, useRef } from "react";
import toast, { Toaster } from "react-hot-toast";
import ReactPixel from 'react-facebook-pixel';
import axios from "axios";


import { Carousel } from "react-responsive-carousel";
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Импортиране на стилизацията

import { useParams, Link } from "react-router-dom";
import { GlobalState } from "../../../GlobalState";
import ProductItem from "../utils/ProductItem/ProductItem";
import Colors from "../utils/Colors/Colors";
import Sizes from "../utils/Size/Size";

const DetailProduct = () => {
  const params = useParams();
  const state = useContext(GlobalState);
  const [products] = state.productsAPI.products;
  const addCart = state.userAPI.addCart;
  const [token] = state.token;
  const [detailProduct, setDetailProduct] = useState([]);
  const [images, setImages] = useState([]);
  const [color, setColor] = state.color;
  const [size, setSize] = state.size;
  const [withoutRegistration, setWithoutRegistration] =
    state.withoutRegistration;
  let [loading, setLoading] = useState(false);
  const carouselRef = useRef(null);

  const handleAddToCart = (product) => {
    ReactPixel.track('AddToCart', {
      content_ids: [product.product_id],
      content_type: 'product',
      value: product.price,
      currency: 'BGN',
    });
    return 0;
  };
console.log(detailProduct.product_id)
  const sendPostRequest = async () => {
    const eventId = window.crypto?.randomUUID?.() || Math.random().toString(36).substring(2, 15);

    const data = {
      event_name: "AddToCart",
      event_data: {
        event_id: eventId, // Изпращаме event_id на бекенда
        em: " ",
        ph: " ",
        fn: " ",
        ln: " ",
        ct: " ",
        st: "",
        zp: "900",
        country: "Bulgaria",
        currency: "BGN",
        value: "",
        contents: [
          {
            id: detailProduct.product_id, // ID на продукта
            quantity: 1, // Пример: добавяне на 1 брой
            item_price: detailProduct.price, // цена за единичен артикул
          },
        ],
        event_source_url: "https://www.princessbutik.bg",
        action_source: "website",
      },
    };

    try {
      const response = await axios.post('/api/trackEvent', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log('Response:', response.data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const goToSlide = (index) => {
    if (carouselRef.current) {
      carouselRef.current.moveTo(index);
    }
  };


  const notify = () =>
    toast.custom(
      (t) => (
        <div className="containerModal">
          <div className="check">
            &#10003; <span>Успешно добавихте продукт кък количката!</span>
          </div>

          <div className="containerButtons">
            <button className="buttonClose" onClick={() => toast.dismiss(t.id)}>
              Продължете с пазаруването
            </button>

            <Link onClick={() => toast.dismiss(t.id)} to="/cart">
              <button className="buttonBuy">&#128722; Към количката</button>
            </Link>
          </div>
        </div>
      ),
      { duration: 7000 }
    );

  useEffect(() => {
    setLoading(false);
    setTimeout(() => {
      setLoading(true);
    }, 300);
  }, [images]);

  const result = images.filter(Boolean);
  useEffect(() => {
    if (params.id) {
      products.forEach((product) => {
        if (product._id === params.id) {
          setImages([
            product.images,
            product.imagesTwo,
            product.imagesThree,
            product.imagesFour,
            product.imagesFive,
            product.imagesSix,
            product.imagesSeven,
            product.imagesEight,
            product.imagesNine,
            product.imagesTen,
            product.imagesEleven,
            product.imagesTwelve,
          ]);
          setDetailProduct(product);
        }
      });
    }
  }, [params.id, products]);

  if (detailProduct.length === 0) {
    return null;
  }
  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="detail">
        <div className="img-detail">
          {loading ? (
           <Carousel
           ref={carouselRef}
           emulateTouch={true}
           infiniteLoop={true}
           preventMovementUntilSwipeScrollTolerance={true}
           useKeyboardArrows={true}
           swipeable={true} // Оставяме swipe, за да може да се плъзга на мобилни
           showArrows={true} // Показва стрелките
           showIndicators={true} // Скрива малките точки, ако не са нужни
           dynamicHeight={true} // Автоматично настройва височината спрямо изображението
           centerMode={false} // Гарантира, че няма странично разместване на мобилни
         >
           {result
             ? result.map((url, index) => (
               <div key={index}>
                 <img
                   className="image"
                   src={`https://lh3.googleusercontent.com/d/${url.url.split("id=")[1]}=s500?authuser=1`}
                   referrerPolicy="no-referrer"
                 />
               </div>
             ))
             : ""}
         </Carousel>
         
          ) : null}
        </div>
        <div className="box-detail">
          <div className="row">
            <h2>{detailProduct.title}</h2>
            <h6>#Код: {detailProduct.product_id}</h6>
          </div>
          <hr />
          <span style={detailProduct.sale ? { color: "#CC2128" } : {}}>
            {Number(detailProduct.price).toFixed(2)} лв.
          </span>
          <br />
          <span
            style={detailProduct.sale ? { textDecoration: "line-through" } : {}}
          >
            {detailProduct.sale ? detailProduct.sale + "лв." : ""}{" "}
          </span>
          <p>{detailProduct.description}</p>
          <p>{detailProduct.content}</p>
          <p>
            Цветове:{" "}
            {
              <Colors
                id={detailProduct._id}
                colors={detailProduct.colors}
                setColor={setColor}
                goToSlide={goToSlide}
              />
            }
          </p>
          <p>
            Размери:{" "}
            {
              <Sizes
                id={detailProduct._id}
                sizes={detailProduct.size}
                setSize={setSize}
                goToSlide={goToSlide}
              />
            }
          </p>
          {/* <p>Продадени: {detailProduct.sold}</p> */}
          <Link
            style={
              detailProduct.sold_out === "sold_out"
                ? { pointerEvents: "none" }
                : {}
            }
            to={
              token || withoutRegistration
                ? color && size
                  ? `${window.location.pathname}`
                  : `${window.location.pathname}`
                : { pathname: "/login", state: window.location.pathname }
            }
            onClick={() => [
              color && size ? notify() : null,
              // handleAddToCart(detailProduct),
              addCart(
                detailProduct,
                withoutRegistration,
                color,
                size,
                setColor,
                setSize
              ),
              //sendPostRequest(),
              color ? null : setColor(""),
              size ? null : setSize(""),
            ]}
            className="cart"
          >
            {detailProduct.sold_out === "sold_out" ? "Изчерпан" : " Купи"}
          </Link>
          {detailProduct.table ? (
            <table>
              <tr>
                <th></th>
                <th>XS</th>
                <th>S</th>
                <th>M</th>
                <th>L</th>
                <th>XL</th>
                <th>XXL</th>
                <th>3XL</th>
                <th>4XL</th>
                <th>5XL</th>
              </tr>

              <tr>
                <th>Гръдна обиколка (см)</th>
                <td>{detailProduct.table.xs_go}</td>
                <td>{detailProduct.table.s_go}</td>
                <td>{detailProduct.table.m_go}</td>
                <td>{detailProduct.table.l_go}</td>
                <td>{detailProduct.table.xl_go}</td>
                <td>{detailProduct.table.xxl_go}</td>
                <td>{detailProduct.table.xxxl_go}</td>
                <td>{detailProduct.table.xxxxl_go}</td>
                <td>{detailProduct.table.xxxxxl_go}</td>
              </tr>
              <tr>
                <th>Талия (см)</th>
                <td>{detailProduct.table.xs_t}</td>
                <td>{detailProduct.table.s_t}</td>
                <td>{detailProduct.table.m_t}</td>
                <td>{detailProduct.table.l_t}</td>
                <td>{detailProduct.table.xl_t}</td>
                <td>{detailProduct.table.xxl_t}</td>
                <td>{detailProduct.table.xxxl_t}</td>
                <td>{detailProduct.table.xxxxl_t}</td>
                <td>{detailProduct.table.xxxxxl_t}</td>
              </tr>
              <tr>
                <th>Ханш (см)</th>
                <td>{detailProduct.table.xs_h}</td>
                <td>{detailProduct.table.s_h}</td>
                <td>{detailProduct.table.m_h}</td>
                <td>{detailProduct.table.l_h}</td>
                <td>{detailProduct.table.xl_h}</td>
                <td>{detailProduct.table.xxl_h}</td>
                <td>{detailProduct.table.xxxl_h}</td>
                <td>{detailProduct.table.xxxxl_h}</td>
                <td>{detailProduct.table.xxxxxl_h}</td>
              </tr>
            </table>
          ) : (
            ""
          )}

          <p style={{ fontWeight: "bold" }}>
            Всички поръчки са с преглед и тест!
          </p>
          <p style={{ fontWeight: "bold" }}>
            Безплатна доставка и връщане на продуктите при покупка на стойност над-99.98лв.
          </p>
        </div>
      </div>
      <hr />
      <div>
        <h2>Свързани продукти</h2>
        <div className="products">
          {products.map((product) => {
            return product.category === detailProduct.category ? (
              <ProductItem key={product._id} product={product} />
            ) : null;
          })}
        </div>
      </div>
    </>
  );
};

export default DetailProduct;
