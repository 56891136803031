import React, {useState, useContext} from 'react';  
import { categories } from "../data";
import styled from "styled-components";
import { GlobalState } from '../../../../GlobalState';
import { mobile } from "../responsive";
import CategoryItem from "./CategoryItem";

const Container = styled.div`
  display: flex;
  padding: 20px;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;

  ${mobile({ 
    padding: "10px", 
    flexDirection: "column", 
    alignItems: "center" 
  })}
`;

const Categories = () => {
    const state = useContext(GlobalState);
    let [category] = state.categoriesAPI.categories;

    return (
        <Container>
        {
          category.map(item => {
            if(item.name === "Ризи" || item.name === "Рокли" || item.name === "Блузи" || item.name === "Панталони") {
              return (<CategoryItem item={item} key={item.id} />);
            }
          })
        }
      </Container>
    )
}

export default Categories;
