import React from 'react'
import Slider from './Slider/Slider';
import Categories from './Categories/Categories'
import Products from './Products/Products';
import FeatureSection from '../FeatureSection/FeatureSection';
import MetaTags from 'react-meta-tags';

const Home = () => {
    return (
        <div>
            <MetaTags>
            <title>Princess Butik - онлайн магазин за дамска мода</title>
            <meta id="meta-description" name="description" content="Вашият онлайн магазин за дамски дрехи - рокли, дънки, якета и блузи | Princess Butik" />
          </MetaTags>
            <Slider />
            <Categories />
            <Products />
            <FeatureSection />
        </div>
    )
}

export default Home
